import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import coverPhoto from '../assets/images/banner-9.jpg'

class ServicesCareContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Care and Contact Evaluations - Carien Zeeman</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>Care & Contact Evaluations</h2>
              </header>
              <span className="image main">
                <img src={coverPhoto} alt="" />
              </span>
              <p>Carien undertakes a comprehensive care and contact evaluation process during divorce matters, which includes the following:</p>
              <li>Assessment of all children involved</li>
              <li>Home visits to both parties</li>
              <li>Interactional analysis between both parties and the children involved</li>
              <li>Gathering of collateral information</li>
              <li>Mediation sessions with involved parties</li>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesCareContact
